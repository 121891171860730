(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(document).ready(function() {

    $('.madlib-selection').on('change',function() {
        var currName = $(this).attr('name');
        var currValue = $(this).val();
        localStorage.setItem(currName, currValue);
    });

    var madlibMap = [
        'woman,body,never-used-nair,minimize-hair-regrowth~shower-cream-argan,wax-legs-body|comparison-guide,how-use-creams',
        'woman,body,never-used-nair,dermatologist-tested~bladeless-rose,shower-cream-sensitive|comparison-guide,how-use-creams',
        'woman,body,never-used-nair,results-longer-than-shaving~spa-sugar,bladeless-rose|comparison-guide,how-use-spaSugar',
        'woman,body,sensitive-skin,minimize-hair-regrowth~shower-cream-sensitive,sensitive-wax-body-legs|comparison-guide,how-use-creams',
        'woman,body,sensitive-skin,dermatologist-tested~shower-cream-sensitive,sensitive-wax-body-legs|comparison-guide,how-use-creams',
        'woman,body,sensitive-skin,results-longer-than-shaving~shower-cream-sensitive,sensitive-wax-body-legs|comparison-guide,how-use-creams',
        'woman,body,coarse-hair,minimize-hair-regrowth~body-cream-aloe,shower-cream-argan|comparison-guide,how-use-creams',
        'woman,body,coarse-hair,dermatologist-tested~body-cream-cocoa,shower-cream-argan|comparison-guide,how-use-creams',
        'woman,body,coarse-hair,results-longer-than-shaving~body-cream-baby-oil,body-spray-argan|comparison-guide,how-use-creams',
        'woman,body,something-new,minimize-hair-regrowth~shower-cream-argan,spa-sugar|comparison-guide,how-use-showerCream',
        'woman,body,something-new,dermatologist-tested~body-cream-baby-oil,body-spray-argan|comparison-guide,how-use-creams',
        'woman,body,something-new,results-longer-than-shaving~shower-cream-argan,body-cream-aloe|comparison-guide,how-use-showerCream',
        'woman,legs,never-used-nair,minimize-hair-regrowth~bladeless-lavender,leg-mask-shea|how-remove-legHair,how-use-creams',
        'woman,legs,never-used-nair,dermatologist-tested~leg-mask-charcoal,bladeless-rose|how-remove-legHair,how-use-legMask',
        'woman,legs,never-used-nair,results-longer-than-shaving~bladeless-lavender,leg-mask-seaweed|how-remove-legHair,how-use-creams',
        'woman,legs,sensitive-skin,minimize-hair-regrowth~shower-cream-sensitive,spa-sugar|how-remove-legHair,how-use-showerCream',
        'woman,legs,sensitive-skin,dermatologist-tested~shower-cream-sensitive,spa-sugar|how-remove-legHair,how-use-showerCream',
        'woman,legs,sensitive-skin,results-longer-than-shaving~shower-cream-sensitive,spa-sugar|how-remove-legHair,how-use-showerCream',
        'woman,legs,coarse-hair,minimize-hair-regrowth~shower-cream-argan,body-cream-aloe|how-remove-legHair,how-use-showerCream',
        'woman,legs,coarse-hair,dermatologist-tested~shower-cream-argan,body-cream-cocoa|how-remove-legHair,how-use-showerCream',
        'woman,legs,coarse-hair,results-longer-than-shaving~body-spray-argan,body-cream-baby-oil|how-remove-legHair,how-use-bodySpray',
        'woman,legs,something-new,minimize-hair-regrowth~wax-legs-body,bladeless-rose|how-remove-legHair,how-use-nairWax',
        'woman,legs,something-new,dermatologist-tested~bladeless-lavender,spa-sugar|how-remove-legHair,how-use-creams',
        'woman,legs,something-new,results-longer-than-shaving~bladeless-lavender,leg-mask-shea|how-remove-legHair,how-use-creams',
        'woman,face,never-used-nair,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,never-used-nair,dermatologist-tested~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,never-used-nair,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,sensitive-skin,minimize-hair-regrowth~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,sensitive-skin,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,sensitive-skin,results-longer-than-shaving~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,coarse-hair,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,coarse-hair,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,coarse-hair,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,something-new,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,something-new,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,face,something-new,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'woman,bikini-pubic-area,never-used-nair,minimize-hair-regrowth~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'woman,bikini-pubic-area,never-used-nair,dermatologist-tested~wax-face-bikini,glide-on-sensitive|how-remove-pubicHair,how-use-nairWax',
        'woman,bikini-pubic-area,never-used-nair,results-longer-than-shaving~spa-sugar,glide-on-sensitive|how-remove-pubicHair,how-use-spaSugar',
        'woman,bikini-pubic-area,sensitive-skin,minimize-hair-regrowth~bikini-cream,wax-face-bikini|how-remove-pubicHair,how-use-creams',
        'woman,bikini-pubic-area,sensitive-skin,dermatologist-tested~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'woman,bikini-pubic-area,sensitive-skin,results-longer-than-shaving~bikini-cream,wax-face-bikini|how-remove-pubicHair,how-use-creams',
        'woman,bikini-pubic-area,coarse-hair,minimize-hair-regrowth~glide-on-argan,glide-on-sensitive|how-remove-pubicHair',
        'woman,bikini-pubic-area,coarse-hair,dermatologist-tested~wax-face-bikini,bikini-cream|how-remove-pubicHair,how-use-nairWax',
        'woman,bikini-pubic-area,coarse-hair,results-longer-than-shaving~spa-sugar,glide-on-argan|how-remove-pubicHair,how-use-spaSugar',
        'woman,bikini-pubic-area,something-new,minimize-hair-regrowth~bikini-cream,wax-face-bikini|how-remove-pubicHair,how-use-creams',
        'woman,bikini-pubic-area,something-new,dermatologist-tested~wax-face-bikini,bikini-cream|how-remove-pubicHair,how-use-nairWax',
        'woman,bikini-pubic-area,something-new,results-longer-than-shaving~bikini-cream,spa-sugar|how-remove-pubicHair,how-use-creams',
        'woman,underarms,never-used-nair,minimize-hair-regrowth~glide-on-sensitive,spa-sugar|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,never-used-nair,dermatologist-tested~bladeless-rose,glide-on-sensitive|how-remove-armpitHair,how-use-creams',
        'woman,underarms,never-used-nair,results-longer-than-shaving~glide-on-sensitive,bladeless-lavender|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,sensitive-skin,minimize-hair-regrowth~glide-on-sensitive,none|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,sensitive-skin,dermatologist-tested~glide-on-sensitive,none|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,sensitive-skin,results-longer-than-shaving~glide-on-sensitive,none|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,coarse-hair,minimize-hair-regrowth~body-spray-argan,glide-on-argan|how-remove-armpitHair,how-use-bodySpray',
        'woman,underarms,coarse-hair,dermatologist-tested~shower-cream-argan,body-spray-argan|how-remove-armpitHair,how-use-showerCream',
        'woman,underarms,coarse-hair,results-longer-than-shaving~glide-on-argan,shower-cream-argan|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,something-new,minimize-hair-regrowth~bladeless-lavender,wax-face-bikini|how-remove-armpitHair,how-use-showerCream',
        'woman,underarms,something-new,dermatologist-tested~glide-on-argan,bladeless-rose|how-remove-armpitHair,how-use-glideOn',
        'woman,underarms,something-new,results-longer-than-shaving~bladeless-lavender,wax-face-bikini|how-remove-armpitHair,how-use-showerCream',
        'boy,body,never-used-nair,minimize-hair-regrowth~men-bodycream,shower-cream-sensitive|comparison-guide,how-use-showerCream',
        'boy,body,never-used-nair,dermatologist-tested~body-spray-argan,bladeless-lavender|comparison-guide,how-use-bodySpray',
        'boy,body,never-used-nair,results-longer-than-shaving~men-bodycream,shower-cream-sensitive|comparison-guide,how-use-showerCream',
        'boy,body,sensitive-skin,minimize-hair-regrowth~shower-cream-sensitive,none|comparison-guide,how-use-creams',
        'boy,body,sensitive-skin,dermatologist-tested~shower-cream-sensitive,none|comparison-guide,how-use-creams',
        'boy,body,sensitive-skin,results-longer-than-shaving~shower-cream-sensitive,none|comparison-guide,how-use-creams',
        'boy,body,coarse-hair,minimize-hair-regrowth~men-bodycream,body-spray-argan|comparison-guide,how-use-showerCream',
        'boy,body,coarse-hair,dermatologist-tested~body-spray-argan,men-bodycream|comparison-guide,how-use-bodySpray',
        'boy,body,coarse-hair,results-longer-than-shaving~men-bodycream,body-spray-argan|comparison-guide,how-use-showerCream',
        'boy,body,something-new,minimize-hair-regrowth~body-spray-argan,men-bodycream|comparison-guide,how-use-bodySpray',
        'boy,body,something-new,dermatologist-tested~men-bodycream,body-spray-argan|comparison-guide,how-use-showerCream',
        'boy,body,something-new,results-longer-than-shaving~body-spray-argan,men-bodycream|comparison-guide,how-use-bodySpray',
        'boy,legs,never-used-nair,minimize-hair-regrowth~men-bodycream,bladeless-lavender|how-remove-legHair,how-use-showerCream',
        'boy,legs,never-used-nair,dermatologist-tested~body-spray-argan,leg-mask-charcoal|how-remove-legHair,how-use-bodySpray',
        'boy,legs,never-used-nair,results-longer-than-shaving~men-bodycream,bladeless-lavender|how-remove-legHair,how-use-showerCream',
        'boy,legs,sensitive-skin,minimize-hair-regrowth~shower-cream-sensitive,none|how-remove-legHair,how-use-creams',
        'boy,legs,sensitive-skin,dermatologist-tested~shower-cream-sensitive,none|how-remove-legHair,how-use-creams',
        'boy,legs,sensitive-skin,results-longer-than-shaving~shower-cream-sensitive,none|how-remove-legHair,how-use-creams',
        'boy,legs,coarse-hair,minimize-hair-regrowth~men-bodycream,leg-mask-charcoal|how-remove-legHair,how-use-showerCream',
        'boy,legs,coarse-hair,dermatologist-tested~leg-mask-charcoal,men-bodycream|how-remove-legHair,how-use-bodySpray',
        'boy,legs,coarse-hair,results-longer-than-shaving~men-bodycream,leg-mask-charcoal|how-remove-legHair,how-use-showerCream',
        'boy,legs,something-new,minimize-hair-regrowth~body-spray-argan,men-bodycream|how-remove-legHair,how-use-bodySpray',
        'boy,legs,something-new,dermatologist-tested~men-bodycream,body-spray-argan|how-remove-legHair,how-use-showerCream',
        'boy,legs,something-new,results-longer-than-shaving~body-spray-argan,men-bodycream|how-remove-legHair,how-use-bodySpray',
        'boy,face,never-used-nair,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,never-used-nair,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,never-used-nair,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,sensitive-skin,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,sensitive-skin,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,sensitive-skin,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,coarse-hair,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,coarse-hair,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,coarse-hair,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,something-new,minimize-hair-regrowth~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,something-new,dermatologist-tested~face-cream,sensitive-wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,face,something-new,results-longer-than-shaving~face-cream,wax-face-bikini|how-remove-facialHair,how-use-creams',
        'boy,bikini-pubic-area,never-used-nair,minimize-hair-regrowth~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'boy,bikini-pubic-area,never-used-nair,dermatologist-tested~wax-face-bikini,glide-on-sensitive|how-remove-pubicHair,how-use-nairWax',
        'boy,bikini-pubic-area,never-used-nair,results-longer-than-shaving~spa-sugar,glide-on-sensitive|how-remove-pubicHair,how-use-spaSugar',
        'boy,bikini-pubic-area,sensitive-skin,minimize-hair-regrowth~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'boy,bikini-pubic-area,sensitive-skin,dermatologist-tested~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'boy,bikini-pubic-area,sensitive-skin,results-longer-than-shaving~bikini-cream,glide-on-sensitive|how-remove-pubicHair,how-use-creams',
        'boy,bikini-pubic-area,coarse-hair,minimize-hair-regrowth~glide-on-argan,glide-on-sensitive|how-remove-pubicHair,how-use-glideOn',
        'boy,bikini-pubic-area,coarse-hair,dermatologist-tested~wax-face-bikini,bikini-cream|how-remove-pubicHair,how-use-nairWax',
        'boy,bikini-pubic-area,coarse-hair,results-longer-than-shaving~spa-sugar,glide-on-argan|how-remove-pubicHair,how-use-spaSugar',
        'boy,bikini-pubic-area,something-new,minimize-hair-regrowth~bikini-cream,bladeless-lavender|how-remove-pubicHair,how-use-creams',
        'boy,bikini-pubic-area,something-new,dermatologist-tested~wax-face-bikini,bikini-cream|how-remove-pubicHair,how-use-nairWax',
        'boy,bikini-pubic-area,something-new,results-longer-than-shaving~bikini-cream,spa-sugar|how-remove-pubicHair,how-use-creams',
        'boy,underarms,never-used-nair,minimize-hair-regrowth~men-bodycream,glide-on-sensitive|how-remove-armpitHair,how-use-showerCream',
        'boy,underarms,never-used-nair,dermatologist-tested~glide-on-sensitive,bladeless-rose|how-remove-armpitHair,how-use-bodySpray',
        'boy,underarms,never-used-nair,results-longer-than-shaving~men-bodycream,glide-on-sensitive|how-remove-armpitHair,how-use-showerCream',
        'boy,underarms,sensitive-skin,minimize-hair-regrowth~glide-on-sensitive,spa-sugar|how-remove-armpitHair,how-use-glideOn',
        'boy,underarms,sensitive-skin,dermatologist-tested~glide-on-sensitive,spa-sugar|how-remove-armpitHair,how-use-glideOn',
        'boy,underarms,sensitive-skin,results-longer-than-shaving~glide-on-sensitive,spa-sugar|how-remove-armpitHair,how-use-glideOn',
        'boy,underarms,coarse-hair,minimize-hair-regrowth~men-bodycream,body-spray-argan|how-remove-armpitHair,how-use-showerCream',
        'boy,underarms,coarse-hair,dermatologist-tested~body-spray-argan,men-bodycream|how-remove-armpitHair,how-use-bodySpray',
        'boy,underarms,coarse-hair,results-longer-than-shaving~men-bodycream,body-spray-argan|how-remove-armpitHair,how-use-showerCream',
        'boy,underarms,something-new,minimize-hair-regrowth~body-spray-argan,men-bodycream|how-remove-armpitHair,how-use-bodySpray',
        'boy,underarms,something-new,dermatologist-tested~men-bodycream,body-spray-argan|how-remove-armpitHair,how-use-showerCream',
        'boy,underarms,something-new,results-longer-than-shaving~body-spray-argan,men-bodycream|how-remove-armpitHair,how-use-bodySpray'
    ]

    if($('#madlib-prod').length > 0) {
        for(var i in madlibMap) {
            var currItemRaw = madlibMap[i],
                currItem = currItemRaw.split('~'),
                currParams = currItem[0],
                currResultsRaw = currItem[1];
            
            var imaa = localStorage.getItem("imaa") !== null ? localStorage.getItem("imaa"): null,
                looking = localStorage.getItem("looking") !== null ? localStorage.getItem("looking"): null,
                ihave = localStorage.getItem("ihave") !== null ? localStorage.getItem("ihave"): null,
                imalso = localStorage.getItem("imalso") !== null ? localStorage.getItem("imalso"): null; 

            var count = 0;

            //imaa
            if(imaa != null && currParams.indexOf(imaa) !== -1) {
                count++;
            }

            //looking
            if(looking != null && currParams.indexOf(looking) !== -1) {
                count++;
            }

            //ihave
            if(ihave != null && currParams.indexOf(ihave) !== -1) {
                count++;
            }

            //imalso
            if(imalso != null && currParams.indexOf(imalso) !== -1) {
                count++;
            }

            $('#madlib-prod .producttile-container, #madlib-article .article-cards').hide();

            if(count == 4) {
                var currResults = currResultsRaw.split('|'),
                    prod= (currResults[0]).split(','),
                    article = (currResults[1]).split(',');

                for(var z in prod) {
                    var currProd = prod[z];
                    console.log($('#' + currProd));
                    $('#' + currProd).show();
                    $('#' + currProd).addClass('matched');
                }

                for(var x in prod) {
                    var currArticle = article[x];
                    console.log(currArticle);
                    $('#' + currArticle).show();
                    $('#' + currArticle).addClass('matched-article');
                    $('#' + currArticle).addClass('matchedA');
                }

            }

        }
    }

    //country options
    $('.search-main-container .text-blocker').on('click',function() {
        $('.default.page_404 .search-main-container #search-button').trigger('click');
    });

    //sizing option
    $('.size-option .sizeoptionTrigger').on('click',(e) => {
        e.preventDefault();
        var $this = $(e.currentTarget),
            sizeOption = $this.attr('id');
        
        $('.size-data').addClass('d-none');
        $('.sizeoptionTrigger').removeClass('underline');
        $('.'+sizeOption+'.size-data').removeClass('d-none');
        $this.addClass('underline');
    });

    //content detail sizing option
    $('.contentDetailTrigger').on('click',(e) => {
        var $this = $(e.currentTarget),
            sizeOption = $this.attr('id');

        $('.sizeData').addClass('d-none');
        $('.'+sizeOption).removeClass('d-none');
        $('.contentDetailTrigger').removeClass('underline');
        $('.contentDetailTrigger').addClass('underline');

        if(sizeOption == 'standard'){
            $('.contentDetailTrigger').removeClass('underline');
            $('#standard').addClass('underline');
        }
        if(sizeOption == 'metric'){
            $('.contentDetailTrigger').removeClass('underline');
            $('#metric').addClass('underline');
        }
    });

    //measure function
    $('.toggle-measure, .x-btn, .white-btn').on('click',(e) => {
        $('.learn-how-to-measure-container').toggleClass('active-survey');
        $('.pop-up-survey').removeClass('active');
        $('.links').removeClass('survey-pop-up-true');
    });

    //mobile navbar functions
    $(".country-selector, .search-trigger").on('click',(e) => {
        if ($(window).width() <= 767) {
            e.preventDefault();
            var targetClass = $(e.currentTarget).data('targetclass');

            $('.selector-main-container').removeClass('active');
            $('.'+targetClass).addClass('active');
        }
    });

    $(".close").click(function(e){
        e.preventDefault();
        $('.selector-main-container').removeClass('active');
    });

    if($('.main-content.view-collection-maincontainer').length > 0) {
        $('.main-content.view-collection-maincontainer').hide();
        $('.main-content.view-collection-maincontainer.enhanced-sensation').show();
    }
    $('.collection-tabs-container .tab-item').on('click',(e) => {
        e.preventDefault();
        var $this = $(e.currentTarget),
            currid = $this.attr('id');

        $('.collection-tabs-container .tab-item').removeClass('active');
        $('.collection-tabs-container .tab-item[id=' + currid + ']').addClass('active');
        $('.main-content.view-collection-maincontainer').hide();
        $('.main-content.view-collection-maincontainer.'+ currid).show();
    });

    //content-list function
    $('.list').on('click',(e) => {
        var $this = $(e.currentTarget);

        $('.select-text').html($this.data('text'));
        $('.list').removeClass('active');
        $this.addClass('active');
        $('.content-items').addClass('active');
        $('.product-101').addClass('product-101-active');
        $('.sex-tips').addClass('sex-tips-active');
    });

    $('.list').click(function(e){
        e.preventDefault();
        const value = $(this).attr('data-filter');
        if (value == 'all'){
            $('.content-load-btn').show();
            $('.content-items').addClass('content-load-more');
            $('.content-load-more').addClass('d-none');
            $('.content-load-more').slice(0,6).removeClass('d-none');
            $('.product-101').removeClass('product-101-active');
            $('.sex-tips').removeClass('sex-tips-active');
            $('.content-load-btn').show();
        } 

        else{
            $('.content-items').not('.'+value).addClass('d-none');
            $('.content-items').filter('.'+value).removeClass('d-none');
            $('.content-items').filter('.'+value).addClass('content-load-more');
            $('.content-load-more').addClass('d-none');
            $('.content-load-more').filter('.'+value).slice(0,6).removeClass('d-none');
            $('.content-items').not('.'+value).removeClass('content-load-more');
            $('.content-load-btn').hide();
        };
    });

    $('.list').click(function(e){
        e.preventDefault();
        const value = $(this).attr('data-filter');
        if (value == 'product-101'){
            $('.content-load-btn').show();
        } 
    });

    $(window).scroll(function() {

        if ($(window).width() <= 768) {
            if($(window).scrollTop() >=492){
                $('.view-alltopic-maincontainer').addClass('fixed');
                $('.content-items-container').addClass('active');
            }else{
                $('.view-alltopic-maincontainer').removeClass('fixed');
                $('.content-items-container').removeClass('active');
            }
        }
        if ($(window).width() <= 425) {
            if($(window).scrollTop() >=535){
                $('.view-alltopic-maincontainer').addClass('fixed');
                $('.content-items-container').addClass('active');
            }else{
                $('.view-alltopic-maincontainer').removeClass('fixed');
                $('.content-items-container').removeClass('active');
            }
        }
        if ($(window).width() <= 375) {
            if($(window).scrollTop() >=559){
                $('.view-alltopic-maincontainer').addClass('fixed');
                $('.content-items-container').addClass('active');
            }else{
                $('.view-alltopic-maincontainer').removeClass('fixed');
                $('.content-items-container').removeClass('active');
            }
        }
        if ($(window).width() > 768) {
            if($(window).scrollTop() >=541){
                $('.view-alltopic-maincontainer').addClass('fixed');
                $('.content-items-container').addClass('active');
            }else{
                $('.view-alltopic-maincontainer').removeClass('fixed');
                $('.content-items-container').removeClass('active');
            };
        }
    });

    // //plp load more

    // $('.plp-load-more').on('click', (e)=>{
    //     if($('.load-more').hasClass('plp-load-more')){
    //         $('.producttile:hidden').slice(0,12).removeClass('d-none');
    //         if($('.producttile:hidden').length ==0){
    //             $('.plp-load-more').hide();
    //         };
    //     }
    // });

    // $(document).on('click','.filter-option', (e)=>{
    //     e.preventDefault();
    //     $('.load-more').removeClass('plp-load-more');
    //     $('.load-more').addClass('plp-matched-btn');
    //     $('.matched').addClass('d-n');
    //     $('.matched').slice(0,12).removeClass('d-n');
    //     if($('.matched:hidden').length ==0){
    //         $('.load-more').hide();
    //     }else{
    //         $('.load-more').show();
    //     }   
    // });

    // $('.matched').addClass('d-n');
    // $('.matched').slice(0,12).removeClass('d-n');

    // $(document).on('click','.plp-matched-btn', (e)=>{
    //     e.preventDefault();
    //     $('.matched:hidden').slice(0,12).removeClass('d-n');
    //     if($('.matched:hidden').length ==0){
    //         $('.load-more').hide();
    //     }else{
    //         $('.load-more').show();
    //     }
    // });

    //content list load more function
    $('.content-items').addClass('content-load-more');
    $('.content-load-more').addClass('d-none');
    $('.content-load-more').slice(0,6).removeClass('d-none');

    $('.content-load-btn').on('click', (e)=>{
        e.preventDefault();
        $('.content-load-more:hidden').slice(0,6).removeClass('d-none');
        
        if($('.content-load-more:hidden').length ==0){
            $('.content-load-btn').hide();
        };
    });

    //links with filter needed
    $('.topic, .nav-topic').on('click',(e)=>{
        var $this = $(e.currentTarget),
            topic = $this.data('topic');

            localStorage.setItem('topic', topic);
    });

    if ('topic' in localStorage){
        var topics = localStorage.getItem('topic');

            $('.content-listing-hide .content-items').addClass('content-detail-items');

        if(topics == 'product'){
            $('.content-load-btn').show();
            $('.content-detail-items').addClass('d-none');
            $('.content-items').removeClass('content-load-more');
            $('.product-101').addClass('content-load-more');
            $('.product-101').slice(0,6).removeClass('d-none');
            $('.list').removeClass('active');
            $('.product101').addClass('active');
            $('.select-text').html('product 101');
        }  

        if(topics == 'tips'){
            $('.content-load-btn').hide();
            $('.content-detail-items').addClass('d-none');
            $('.content-items').removeClass('content-load-more');
            $('.sex-tips').addClass('content-load-more');
            $('.sex-tips').slice(0,6).removeClass('d-none');
            $('.list').removeClass('active');
            $('.sexTips').addClass('active');
            $('.select-text').html('sex tips');
            $('.sex-tips').addClass('sex-tips-active');
        }
        
        if(topics == 'healthWellness'){
            $('.content-load-btn').hide();
            $('.content-detail-items').addClass('d-none');
            $('.content-items').removeClass('content-load-more');
            $('.health-wellness').addClass('content-load-more');
            $('.health-wellness').slice(0,6).removeClass('d-none');
            $('.list').removeClass('active');
            $('.healthWellness').addClass('active');
            $('.select-text').html('Health + Wellness');
        }
    }    

    $('.list, .allTopics').click(function(e){
        localStorage.removeItem('topic'); 
        $('.tab-item').removeClass('d-none');
    });

    $('.select-option').on('click',(e)=>{
        var  $this = $(e.currentTarget),
            value = $this.attr('data-filter');

        $('.select-dropdown-container').removeClass('active');

        if(value == 'all'){
            $('.tab-item').removeClass('active');
            $('.tab-item.all').addClass('active');
        }
        if(value == 'product-101'){
            $('.tab-item').removeClass('active');
            $('.tab-item.product101').addClass('active');
        }
        if(value == 'sex-tips'){
            $('.tab-item').removeClass('active');
            $('.tab-item.sexTips').addClass('active');
        }
        if(value == 'health-wellness'){
            $('.tab-item').removeClass('active');
            $('.tab-item.healthWellness').addClass('active');
        }
        
    });
    
    //hide top footer
    if ($('.hide-footer-top').data('footer') == 'footerHide') {
        $('.footer-top').hide();
        $('footer').addClass('survey-bg');
    };

    if ($('.hide-footer-col').data('footer') == 'footerHideCol') {
        $('.footer-success-hide').hide();
        $('footer').addClass('bg-width');
        $('.footer-top').addClass('footer-top-height');
        $('.dirty-talk-container').addClass('signup-success-footer-height');
    };

    //add padding to fotter
    if ($('.padding-footer-top').data('footer-padding') == 'footerPadding') {
        $('footer').addClass('activePadding');
    };

    //specs functionality 
    $('.specs-toggle').on('click',(e) => {
        e.preventDefault();
        var $this = $(e.currentTarget);
        
        $('.specs-toggle').removeClass('active');
        $this.addClass('active');
        $('.specs-desc').html($this.data('text'));
        
    });

    //related pagination mobile 
    if ($('.relatedPagination').data('pagination') == 'dataTrue'){
        $('.content-items').addClass('grid-item');
        $('.content-item-desc').addClass('content-item-desc-mobile-minHeight');
        $('.producttile').addClass('grid-item');
    }

    //survey-pre-pop-up
    $('.survey-pre-pop-up-window').on('click',(e) => {
        $('.links').addClass('survey-pop-up-true'); 
    });

    setTimeout(function(){
        var currActiveQ = $('.links').closest('.survey-questions-container').find('.questions.active');
        if(currActiveQ.length > 0) {
            if(currActiveQ.data('value') == 'its gotta be latex-free') {
                $('.links').removeAttr("onclick");
                $('.links').addClass('survey-pop-up-true');
                $('.pop-up-survey').addClass('active'); 
                $('.links').trigger('click');
            }
        }
    }, 1000);

    $('.links').on('click',(e) =>{
        if($('.links').hasClass('survey-pop-up-true')){
            e.preventDefault();
            $('.pop-up-survey').addClass('active'); 
        } else {
            $('.links').removeClass('survey-pop-up-true');
        }
    });

    $('.questions').on('click',(e) =>{
        if($('.links').hasClass('survey-pop-up-true')){
            $('.links').removeClass('survey-pop-up-true');
        }
    });
    
    //survey active functionality 
    $('.questions').on('click',(e) => {
        e.preventDefault();
        var $this = $(e.currentTarget);
        
        $('.questions').removeClass('active');
        $('.links').removeClass('survey-next-btn');
        $('.next-button').addClass('red-btn');
        $('.next-button').removeClass('next-button').addClass('red-btn');
        $this.addClass('active');
        
    });

    $('[data-toggle="tooltip"]').tooltip();

    if($('.pdp-img').length > 0) {
        var currPDPContainerWidth = $('.pdp-img').outerWidth(true) / 2;
        $('.pdp-img').css("background-position-x", currPDPContainerWidth +"px");
        var ismobile = window.matchMedia('(max-width: 767px)');
        if(ismobile.matches == false) {
            var currPDPContainerHeight = $('.pdp-img').outerHeight(true) / 3.5;
            $('.pdp-img img').css("top", currPDPContainerHeight +"px");
        } else {
            $('.pdp-img img').css("top", "50px");
        }
        $(window).on('resize',() => {
            var currPDPContainerWidth = $('.pdp-img').outerWidth(true) / 2;
            $('.pdp-img').css("background-position-x", currPDPContainerWidth +"px");
            if(ismobile.matches == false) {
                var currPDPContainerHeight = $('.pdp-img').outerHeight(true) / 3.5;
                $('.pdp-img img').css("top", currPDPContainerHeight +"px");
            } else {
                $('.pdp-img img').css("top","50px");
            }
        });

        $('.img-thumb-container .img-thumb').on('click',(e) => {
            var $this = $(e.currentTarget),
                currImg = $this.find('img').attr('src');
            
            $('.pdp-img .main-img img').attr('src',currImg);    
        });
    }

    $(window).on('resize',() => {
        $('.main-menu').removeClass('active');
        $('header').removeClass('active');
        $('.sub-menu-container,.top-menu').removeClass('active');
        $('.sub-menu-container').css("margin-left", "25px");
        $('.hamburger-button,.has-submenu').removeClass('active');
        $('.sub-menu-bg').removeClass('active');
        $('.search-drop-down').removeClass('country-active');
        $('.selector-main-container').removeClass('active');   
        $('.location-dropdown').removeClass('active'); 
    });

    $(document).on('click','.hamburger-button',(e) => {
        $(e.currentTarget).toggleClass('active');
        $('.main-menu').toggleClass('active');
        $('header').toggleClass('active');
    });

    $(document).on('click','.has-submenu',(e) => {
        var currID = $(e.currentTarget).attr('id');
        var currActive = $('.top-menu.active').attr('id');
        if(currID == currActive) {
            $('.sub-menu-container,.top-menu').removeClass('active');
            $('.sub-menu-bg').removeClass('active');
        } else {
            var marginLeft = 25;
            var ismobile = window.matchMedia('(max-width: 767px)');
            var currWindowWidth = window.innerWidth;
            var spacer = 15;
            if(currWindowWidth > 1199) {
                spacer = 40;
            }

            if(ismobile.matches == false) {
                var fullwidth = 0;
                $('.main-menu a.top-menu').each(function() {
                    var currWidth = $(this).outerWidth(true);
                    fullwidth += currWidth;
                });
                var menuFullWidth = $('.main-menu').outerWidth(true);
                marginLeft = ((menuFullWidth - fullwidth) + spacer);
            }

            $('.sub-menu-container,.top-menu').removeClass('active');
            $('.'+ currID + '-list').toggleClass('active');
            $('.'+ currID + '-list').css("margin-left", marginLeft + "px");
            $(e.currentTarget).toggleClass('active');
            $('.sub-menu-bg').addClass('active');
        }
    });

    //will detect size of screen to prevent collection image from being shorter that the parent 
    var ismobile = window.matchMedia('(max-width: 768px)');
    $('.view-collection-maincontainer .view-collection-bgimg').each(function() {
        var currHeight = $('.view-collection-maincontainer').outerHeight();
        $(".view-collection-maincontainer .view-collection-bgimg").css("height", currHeight +'px');
    });
    

    $(window).resize(function() {
        $('.view-collection-maincontainer .view-collection-bgimg').each(function() {
            var currHeight = $('.view-collection-maincontainer').outerHeight();
            $(".view-collection-maincontainer .view-collection-bgimg").css("height", currHeight +'px');
        });
    });


    //will apply show/hide filter items
    // $(document).on('click','.filter-trigger',(e) => {
    //     if($(e.currentTarget).hasClass('active')) {
    //         $('.filter-items-container').removeClass('active');
    //         $(e.currentTarget).removeClass('active');
    //         $('.filter-actions').removeClass('active');
    //     } else {
    //         $('.filter-items-container').addClass('active');
    //         $(e.currentTarget).addClass('active');
    //         $('.filter-actions').addClass('active');
    //     }
    // });

    $('.filter-trigger').on('click', (e)=>{
        $('.filter-filter-items-main-container').toggleClass('active');
        $('.filter-trigger').toggleClass('active');
    });

    //will detect clicks outside to prevent filter from being open even clicked outside
    $(document).on("click", function(e){
        if(!$(e.target).closest(".filter-dropdown-container").length) {
            $('.filter-dropdown-container').removeClass('active');
        }

        if(!$(e.target).closest(".select-dropdown-container").length) {
            $('.select-dropdown-container').removeClass('active');
        }
    });

    //will apply filter dropdown events
    $('.filter-drowndown-trigger').on('click', function(e) {
        var curr = $(this);
        var dropdown = curr.closest('.filter-dropdown-container');
        if(dropdown.hasClass('active')) {
            dropdown.removeClass('active');
        } else {
            $('.filter-dropdown-container').removeClass('active');
            dropdown.addClass('active');
        }
    }); 

    setTimeout(function(){
        var prodtype = $('.search-result-container').data('prodtype');
        $('.search-result-text').html('Showing: ' + $('.producttile-container').length + ' ' + 'Products');

        if(location.search != null && location.search != ''){
            var currQString = (location.search).replace('?','');
            var currQStringArr = currQString.split('&');
            if(currQStringArr.length > 0) {
                for(var i in currQStringArr) {
                    var currQVar = currQStringArr[i];
                    var keyVal = currQVar.split('=');
                    $('.filter-options').each(function(e) {
                        var $this = $(this);
                        console.log($this.data('filtertype'));
                        if($this.data('filtertype') == keyVal[0]) {
                            $this.find('.filter-option').each(function(e) {
                                $curr = $(this);
                                console.log($curr.data('value'));
                                if($curr.data('value') == keyVal[1]) {
                                    $curr.trigger('click');
                                }
                            });
                        }
                    });
                }
            }
        }
    }, 1000);

    $('.filter-option').on('click',function(e) {
        e.preventDefault();
        var curr = $(this);
        curr.closest('.filter-options').find('.filter-option').each(function() {
            $(this).removeClass('selected');
        });
        curr.addClass('selected');
        curr.closest('.filter-dropdown-container').find('.filter-text').html(curr.html());
        curr.closest('.filter-dropdown-container').find('.filter-text').data('value',$(this).data('value'));
        var currFilterType = [];
        $('.filter-options .selected').each(function() {
            var filtertype = $(this).closest('.filter-options').data('filtertype');
            var currVal = $(this).data('value');
            currFilterType.push(filtertype + '|' + currVal);
        });
        
        var totalCount = 0;
        $('.grid-container .producttile-container').each(function() {
            if(currFilterType.length > 0) {
                var totalMatched = 0;
                for(var z in currFilterType) {
                    var currFilterTypeRAW = currFilterType[z];
                    var currFilterTypeArr = currFilterTypeRAW.split('|');
                    var filter = currFilterTypeArr[0];
                    var filtervalue = currFilterTypeArr[1];
                    var filterMainVal = $('.filter-options[data-filtertype='+filter+']').closest('.filter-dropdown-container').find('.filter-text').html();
                
                    if(filterMainVal != 'All') {
                        if(typeof filter !== 'undefined' && typeof $(this).data(filter) !== 'undefined') {
                            if( ($(this).data(filter)).indexOf(filtervalue) != -1 ) {
                                totalMatched++;
                            }
                        }
                    } else {
                        totalMatched++;
                    }
                }
                if(totalMatched == currFilterType.length) {
                    totalCount++;
                    $(this).addClass('matched');
                } else {
                    $(this).removeClass('matched');
                }
            }
        });
        $('.search-result-container .search-result-text').html('Showing: ' + totalCount + ' Products');
        
        
        
        $('.grid-container .producttile-container:not(.matched)').addClass('d-none');
        $('.filter-dropdown-container').removeClass('active');

    });

    $('.selection').on('click',(e) =>{
        var $this = $(e.currentTarget);

        $('.selection').removeClass('selection-active');
        $this.addClass('selection-active');
    });

    if($('.selection-option .selection.selection-active').length > 0) {
        var currActiveOption = $('.selection-option .selection.selection-active');
        if(currActiveOption.data('filter') != '') {
            $('.surey-filter-container .producttile').addClass('d-none');
            $('.'+ currActiveOption.data('filter') ).removeClass('d-none');
        }
    }

    $('.selection').click(function(e){
        var currActiveOption = $(this);
        if(currActiveOption.data('filter') != '') {
            $('.surey-filter-container .producttile').addClass('d-none');
            $('.'+ currActiveOption.data('filter') ).removeClass('d-none');
        }
    });
    

    //will apply filter dropdown events faq page
    $('.select-drowndown-trigger').on('click', function(e) {
        var curr = $(this);
        var dropdown = curr.closest('.select-dropdown-container');
        if(dropdown.hasClass('active')) {
            dropdown.removeClass('active');
        } else {
            $('.select-dropdown-container').removeClass('active');
            dropdown.addClass('active');
        }
    });

    $('.first-topic-faq-title .topic-trigger-first-topic').on('click',(e) => {
        var $this = $(e.currentTarget),
            curContainer = $this.closest('.first-topic-container'),
            currContent = curContainer.find('.first-topic-content');
        
        $this.toggleClass('active');
        currContent.toggleClass('active');
        
    });

    $('#plp-pageSelector').on('change',(e) => {
        var $this = $(e.currentTarget);
        var currVal = $this.val();
        if(currVal != null) {
            var currURLArr = (window.location.href).split('/');
            var lastIndex = currURLArr.length - 1;
            currURLArr[lastIndex] = 'plp-' + currVal + '.aspx';
            var newUrl = currURLArr.join('/');
            window.location = newUrl;
        }
    });

    if ($.fn.slick) { 
        var ismobile = window.matchMedia('(max-width: 767px)');
        if(ismobile.matches == true) { 
            $('.grid-container,.related-items-container,.product-cards-container,.content-list-items-wrapper').each(function() {
                
                var isnotPLP = $(this).closest('.plp-maincontent-container').length;
                if(isnotPLP == 0) {
                    if($(this).hasClass('slick-initialized') == false) {
                        $(this).slick({
                            infinite: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            swipeToSlide: true,
                            dots: true,
                            touchThreshold:100
                        });
                    }
                }
            });
        } else {
            $('.grid-container,.related-items-container,.product-cards-container,.content-list-items-wrapper').each(function() {
                var isnotPLP = $(this).closest('.plp-maincontent-container').length;
                if(isnotPLP == 0) {
                    if($(this).hasClass('slick-initialized')) {
                        $(this).slick('unslick');
                    }
                }
            });
        }

        $( window ).resize(function() {
            var ismobile = window.matchMedia('(max-width: 767px)');
            if(ismobile.matches == true) { 
                $('.grid-container,.related-items-container').each(function() {
                    var isnotPLP = $(this).closest('.plp-maincontent-container').length;
                    if(isnotPLP == 0) {
                        if($(this).hasClass('slick-initialized') == false) {
                            $(this).slick({
                                infinite: false,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: true,
                                dots: true,
                                swipeToSlide: true,
                                touchThreshold:100
                            });
                        }
                    }
                });
            } else {
                $('.grid-container,.related-items-container').each(function() {
                    var isnotPLP = $(this).closest('.plp-maincontent-container').length;
                    if(isnotPLP == 0) {
                        if($(this).hasClass('slick-initialized')) {
                            $(this).slick('unslick');
                        }
                    }
                });
            }
        });
    }

    if ($.fn.owlCarousel) { 
        $('.owl-carousel').owlCarousel({
            rtl:true,
            loop:true,
            nav:false,
            responsive:{
                0:{
                    items:3
                },
                768:{
                    items:5
                },
                1024:{
                    items:8
                }
            }
        });
    }

    $('.survey-next-btn').on('click',(e) => {
        $this = $(e.currentTarget);
        var currQuestion  = $this.attr('id');
        var currVal = $('.questions.active').data('value');
        localStorage.setItem(currQuestion,currVal);
    });

    if ("survey-q1" in localStorage && "survey-q2" in localStorage && "survey-q4" in localStorage) { 
        var q0 = localStorage.getItem('survey-q0');
        var q1 = localStorage.getItem('survey-q1');
        var q2 = localStorage.getItem('survey-q2');
        var q4 = localStorage.getItem('survey-q4');
        var currProfile,currCRecomm,currTRecomm,currFit;
        var similarFit = [
            {
                'Fit1': 'All Nighter|Can you have too much of a good thing? You\'re on a quest to find out. Condoms can help you prolong the magic so you and your partner can go again, and again, and again. You\'re sure to make a lasting impression.',
                'Fit2': 'Body Blender|You\'re all about closeness and intimacy. You want a condom that is subtle and doesn\'t come between you and your partner. Trojan&trade; can feel like next to nothing - you won\'t feel like it\'s there, but you can trust it\'s got you covered when it comes to protection.'
            },
            {
                'Fit1': 'Pleasure Enhancer|You\'re all about mixing it up, having new experiences and maxing out the good feelings. You\'re bold, adventurous and sensation-seeking, and Trojan&trade; is here to protect you from risk while you and your partner push the limits of pleasure.',
                'Fit2': 'Partner Firster|You don\'t need a condom when you\'re going it alone, so when you reach for a Trojan&trade; you\'re all about pleasure for her. It may be better for you when it\'s better for them, but your altruism is still admirable.'
            },
            {
                'Fit1': 'Straight Shooter|Honesty, integrity and no-nonsense. You want a classic, reliable, trusted condom to be there whenever the time is right. Hey, things are classic for a reason.',
                'Fit2': 'Pleasure Enhancer|You\'re all about mixing it up, having new experiences and maxing out the good feelings. You\'re bold, adventurous and sensation-seeking, and Trojan&trade; is here to protect you from risk while you and your partner push the limits of pleasure.'
            },
            {
                'Fit1': 'Mixologist|Expand your options and explore the heights of sensation. From prolonging the magic, to bumpy rides, to sheer pleasure, you\'re looking for variety that\'s wide and deep.',
                'Fit2': 'Body Blender|You\'re all about closeness and intimacy. You want a condom that is subtle and doesn\'t come between you and your partner. Trojan&trade; can feel like next to nothing - you won\'t feel like it\'s there, but you can trust it\'s got you covered when it comes to protection.'
            },
            {
                'Fit1': 'All Nighter|Can you have too much of a good thing? You\'re on a quest to find out. Condoms can help you prolong the magic so you and your partner can go again, and again, and again. You\'re sure to make a lasting impression.',
                'Fit2': 'Pleasure Enhancer|You\'re all about mixing it up, having new experiences and maxing out the good feelings. You\'re bold, adventurous and sensation-seeking, and Trojan&trade; is here to protect you from risk while you and your partner push the limits of pleasure.'
            },
            {
                'Fit1': 'Partner Firster|You don\'t need a condom when you\'re going it alone, so when you reach for a Trojan&trade; you\'re all about pleasure for her. It may be better for you when it\'s better for them, but your altruism is still admirable.',
                'Fit2': 'Straight Shooter|Honesty, integrity and no-nonsense. You want a classic, reliable, trusted condom to be there whenever the time is right. Hey, things are classic for a reason.'
            }
        ];
        var Profiles = [
            {
                'ProfileName': 'Straight Shooter',
                'ProfileDesc': 'Honesty, integrity and no-nonsense. You want a classic, reliable, trusted condom to be there whenever the time is right. Hey, things are classic for a reason.'
            },
            {
                'ProfileName': 'Mixologist',
                'ProfileDesc': 'Expand your options and explore the heights of sensation. From prolonging the magic, to bumpy rides, to sheer pleasure, you\'re looking for variety that\'s wide and deep.'
            },
            {
                'ProfileName': 'Body Blender',
                'ProfileDesc': 'You\'re all about closeness and intimacy. You want a condom that is subtle and doesn\'t come between you and your partner. Trojan&trade; can feel like next to nothing - you won\'t feel like it\'s there, but you can trust it\'s got you covered when it comes to protection.'
            },
            {
                'ProfileName': 'Pleasure-Enhancer',
                'ProfileDesc': 'You\'re all about mixing it up, having new experiences and maxing out the good feelings. You\'re bold, adventurous and sensation-seeking, and Trojan&trade; is here to protect you from risk while you and your partner push the limits of pleasure.'
            },
            {
                'ProfileName': 'Partner-Firster',
                'ProfileDesc': 'You don\'t need a condom when you\'re going it alone, so when you reach for a Trojan&trade; you\'re all about pleasure for her. It may be better for you when it\'s better for them, but your altruism is still admirable.'
            },
            {
                'ProfileName': 'Sensitive Lover',
                'ProfileDesc': 'All the pleasure and protection of a Trojan for latex-sensitive folks. Super-thin, super-strong and designed to transmit body heat so you get all the sensations you want and none that you don\'t.'
            },
            {
                'ProfileName': 'All Nighter',
                'ProfileDesc': 'Can you have too much of a good thing? You\'re on a quest to find out. Condoms can help you prolong the magic so you and your partner can go again, and again, and again. You\'re sure to make a lasting impression.'
            }
        ];
        var cRecomm = [
            {
                'Products': 'Enz,Bareskin,Ultra Ribbed,Ultra Thin'
            },
            {
                'Products': 'Magnum,Magnum XL'
            },
            {
                'Products': 'Pleasure Pack,All the Feels,Bareskin Variety'
            },
            {
                'Products': 'Magnum Ecstasy,Magnum,Magnum Thin'
            },
            {
                'Products': 'Bareskin Studded,Bareskin,Ultra Thin'
            },
            {
                'Products': 'Magnum Bareskin,Magnum Thin'
            },
            {
                'Products': 'Double Ecstasy,Bareskin,Her Pleasure Ecstasy,Pleasure Pack'
            },
            {
                'Products': 'Magnum Ecstasy,Magnum Ribbed,Magnum Gold'
            },
            {
                'Products': 'Willa,Her Pleasure Ecstasy,Willa Ribbed'
            },
            {
                'Products': 'Naturalamb,Bareskin Supra'
            },
            {
                'Products': 'Extended Pleasure'
            }
        ];

        var tRecomm = [
            {
                'Products': 'Closer,Sensitive Touch'
            },
            {
                'Products': 'Arouses Releases,Arouses Intensifies'
            },
            {
                'Products': 'Vulva|Power Wand Deep Wave,Pulse Compact Vibrator,Divine,Futter~Penis|Tantrix'
            },
            {
                'Products': 'Tandem,2-in-1 Vibrating Ring Finger Massager,Ultra Touch,Bullet'
            },
            {
                'Products': '2-in-1 Vibrating Ring Finger Massager,H2O Closer Lube'
            },
            {
                'Products': 'Delay Spray,Power Wand Deep Wave'
            }
        ];

        if(q0 == 'its gotta be latex-free') {
            currProfile = Profiles[5];
            currFit = similarFit[0];
            currCRecomm = cRecomm[9];
            currTRecomm = tRecomm[4];
        } else if(q0 == 'To Last Longer') {
            currProfile = Profiles[6];
            currFit = similarFit[0];
            currCRecomm = cRecomm[10];
            currTRecomm = tRecomm[5];
        } else {

            if(q1 == 'protection, no frills') {
                currProfile = Profiles[0];
                currFit = similarFit[0];

                if(q2 == 'Snug') {
                    currCRecomm = cRecomm[0];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Standard') {
                    currCRecomm = cRecomm[0];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Wide') {
                    currCRecomm = cRecomm[1];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }
            }

            if(q1 == 'give me variety') {
                currProfile = Profiles[1];
                currFit = similarFit[1];
                if(q2 == 'Snug') {
                    currCRecomm = cRecomm[2];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Standard') {
                    currCRecomm = cRecomm[2];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Wide') {
                    currCRecomm = cRecomm[3];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }
            }

            if(q1 == 'the thinner the better') {
                currProfile = Profiles[2];
                currFit = similarFit[2];
                if(q2 == 'Snug') {
                    currCRecomm = cRecomm[4];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Standard') {
                    currCRecomm = cRecomm[4];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Wide') {
                    currCRecomm = cRecomm[5];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }
            }

            if(q1 == 'an adventure') {
                currProfile = Profiles[3];
                currFit = similarFit[3];
                if(q2 == 'Snug') {
                    currCRecomm = cRecomm[6];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Standard') {
                    currCRecomm = cRecomm[6];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Wide') {
                    currCRecomm = cRecomm[7];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }
            }

            if(q1 == 'pleasure for my partner') {
                currProfile = Profiles[4];
                currFit = similarFit[4];
                if(q2 == 'Snug') {
                    currCRecomm = cRecomm[8];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Standard') {
                    currCRecomm = cRecomm[8];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }

                if(q2 == 'Wide') {
                    currCRecomm = cRecomm[3];
                    if(q4 == 'Wet and Wild') {
                        currTRecomm = tRecomm[0];
                    }

                    if(q4 == 'More Sensations') {
                        currTRecomm = tRecomm[1];
                    }

                    if(q4 == 'Toys for me') {
                        currTRecomm = tRecomm[2];
                    }

                    if(q4 == 'Toys for us both') {
                        currTRecomm = tRecomm[3];
                    }
                }
            }
        }

        $('.survey-result-heading .profileName').html(currProfile.ProfileName);
        $('.survey-result-heading .profileDesc').html(currProfile.ProfileDesc);

        if(currCRecomm != null) {
            var currCRecommArr = (currCRecomm.Products).split(',');
            if(currCRecommArr.length > 0) {
                $('.grid-container-survey.survey-result-try-these-on .producttile').addClass('d-none');
                for(var i in currCRecommArr) {
                    var str = currCRecommArr[i];
                    str = str.replace(/\s+/g, '-').toLowerCase();
                    $('.grid-container-survey.survey-result-try-these-on .producttile#'+str).removeClass('d-none').addClass('survey-product');
                }
            }
        }

        if(currTRecomm != null) {
            var currTRecommArr = (currTRecomm.Products).split('~');
            if(currTRecommArr.length > 0) {
                if(currTRecommArr.length > 1) {
                    $('.grid-container-survey.other-products-you-might-like .producttile').addClass('d-none');
                    for(var b in currTRecommArr) {
                        var currTRecommArrV1Raww = currTRecommArr[b];
                        if(currTRecommArrV1Raww != null && currTRecommArrV1Raww != '') {
                            var currTRecommArrV1RAW = currTRecommArrV1Raww.split('|');
                            var currTRecommArrV1 = currTRecommArrV1RAW[1].split(',');
                            for(var l in currTRecommArrV1) {
                                var currTRecommArrFinal = currTRecommArrV1[l];
                                var strz = currTRecommArrFinal;
                                strz = strz.replace(/\s+/g, '-').toLowerCase();
                                console.log(strz);
                                $('.grid-container-survey.other-products-you-might-like .producttile#'+strz).removeClass('d-none');
                                
                            }
                        }
                        
                    }
                } else {
                    var currTRecommArrFinal = currTRecommArr[0].split(',');
                    console.log(currTRecommArr);
                    $('.selection-option').remove();
                    $('.i-have-a').remove();

                    $('.grid-container-survey.other-products-you-might-like .producttile').addClass('d-none');
                    for(var z in currTRecommArrFinal) {
                        var strz = currTRecommArrFinal[z];
                        strz = strz.replace(/\s+/g, '-').toLowerCase();
                        
                        $('.grid-container-survey.other-products-you-might-like .producttile#'+strz).removeClass('d-none');
                    }
                }

                
            }
        }

        var fit1 = (currFit.Fit1).split('|');
        var fit2 = (currFit.Fit2).split('|');
        console.log(fit1);
        console.log(fit2);
        $('.similar-to-fit .filter-main-container .first .filter-header').html(fit1[0]);
        $('.similar-to-fit .filter-main-container .first .filter-desc').html(fit1[1]);

        $('.similar-to-fit .filter-main-container .second .filter-header').html(fit2[0]);
        $('.similar-to-fit .filter-main-container .second .filter-desc').html(fit2[1]);

        
    }

    //survey result functions
    $('.penis').addClass('d-none');
    $('.survey-product').addClass('d-none');
    $('.survey-product').slice(0,2).removeClass('d-none');

    $('.survey-result-loadmore').on('click' ,(e) =>{
        $('.survey-product:hidden').slice(0,2).removeClass('d-none');

        if($('.survey-product:hidden').length ==0){
            $('.survey-result-loadmore').hide();
        };
    });

    if($('.survey-product:hidden').length ==0){
        $('.survey-result-loadmore').hide();
    };

    $('#wtb-category-filter').on('change',(e) => {
        var $this = $(e.currentTarget),
            currVal = $this.val();
        
        if(currVal != '') {
            $('.wtb-category-options option').hide();
            $('.wtb-category-options option').each(function(e) {
                if(!!$(this).data('category')) {
                    if(($(this).data('category')).toLowerCase() == (currVal).toLowerCase()) {
                        $(this).show();
                    }
                }
            });
        } else {
            $('.wtb-category-options option').show();
        }
    });

    $('.survey-questions .questions').each(function() {
        var currQ = $('.survey-questions').data('question');
        if(!!currQ && currQ in localStorage) {
            var currA = localStorage.getItem(currQ);
            console.log(currA);
            if(!!$(this).data('value')) {
                if(($(this).data('value')).toLowerCase() == currA.toLowerCase()) {
                    $('.survey-questions .questions').removeClass('active');
                    $(this).addClass('active');
                    $('.next-button').addClass('red-btn');
                    $('.red-btn').removeClass('next-button');
                    $('.links').removeClass('survey-next-btn');
                }
            }
        }
    });

    $('.start-over a').on('click',(e) => {
        e.preventDefault();
        for(var i=1;i<5;i++) {
            localStorage.removeItem('survey-q'+i);
        }
        var $this = $(e.currentTarget);
        window.location.href= $this.attr('href');
    });

    
    $('.mobile-pagination .prev,.mobile-pagination .next').on('click',function() {
        var currContainer = $(this).closest('.mobile-pagination').attr('id');
        var nextPage = $(this).data('page');
        
        var prev = $(this).closest('.mobile-pagination').find('.prev');
        var next = $(this).closest('.mobile-pagination').find('.next');
        var page = $(this).closest('.mobile-pagination').find('.page');

        $('.' + currContainer + '-container .grid-wrapper .grid-item').hide();
        $('.' + currContainer + '-container .grid-wrapper .grid-item').each(function(index) {
            if(parseInt(index) == parseInt(nextPage)) {
                $(this).show();
                
                if(nextPage == 0) {
                    next.css({
                        "opacity": "1"
                    });
                    prev.css({
                        "opacity": ".5"
                    });

                }
                else if(nextPage == 2) {
                    next.css({
                        "opacity": ".5"
                    });
                    prev.css({
                        "opacity": "1"
                    });
                } else {
                    next.css({
                        "opacity": "1"
                    });
                    prev.css({
                        "opacity": "1"
                    });
                }

                var newPrev = parseInt(nextPage) > 0 ? parseInt(nextPage) - 1 : 0;
                var newNext = (parseInt(nextPage) + 1) < 2 ? parseInt(nextPage) + 1 : 2;
                
                prev.data('page',newPrev);
                next.data('page',newNext);
                page.data('currentpage',nextPage);
                page.html((parseInt(nextPage) + 1) +'/3');
            }
        });

    });

    //education functions
    $('.clp-main-container .content-items-ed').addClass('d-none');
    $('.clp-main-container .content-items-ed').slice(0,6).removeClass('d-none');
    var clpTopic = 'content-items-ed';

    $('.plp-filter-container .filter-option').on('click', (e)=>{
        var $this = $(e.currentTarget),
            topic = $this.attr('data-topic');

        $('.clp-main-container .content-items-ed').addClass('d-none');
        $('.plp-filter-container .filter-option').removeClass('active');
        $this.addClass('active');
        clpTopic = topic;
        $('.clp-main-container .' + topic).slice(0,6).removeClass('d-none');
        if(topic === 'all'){
            clpTopic = 'content-items-ed';
            $('.clp-main-container .content-items-ed').slice(0,6).removeClass('d-none');
        }
    });

    $('.clp-main-container .primary-btn').on('click', (e)=>{
        $('.clp-main-container .' + clpTopic + ':hidden').slice(0,6).removeClass('d-none');
    });

    setInterval(function(){ 
        if($('.clp-main-container .' + clpTopic + ':hidden').length ==0){
            $('.clp-main-container .primary-btn').addClass('d-none');
        }else{
            $('.clp-main-container .primary-btn').removeClass('d-none');
        };
    },);

    //where to buy store selection
    $('.store-selection .selections').on('click', (e)=>{
        var $this = $(e.currentTarget);

            $('.store-selection .selections').removeClass('active');
            $this.addClass('active');    
    });

    //location drop down
    $('.location-dropdown-selector').on('click', (e)=>{
        $('.location-selection').toggleClass('active');
        $('.selected').toggleClass('active');
    });

    $('.country-selector, .location-close-btn').on('click', (e)=>{
        $('.location-dropdown').toggleClass('active');
    });
    
    //search drop down
    $('.search-container, .search-close-btn').on('click', (e)=>{
        $('.search-dropdown').toggleClass('active');
    });

    //plp function
    $('.search-result-container .producttile-container .producttile').addClass('productContainer');
    $('.search-result-container .producttile-container .productContainer').removeClass('producttile');

    $('.plp-maincontent-container .filter-filter-items-main-container .filter-option').on('click', (e)=>{
        $('.other-product-btn').addClass('plp-loadMore');
        $('.plp-loadMore').removeClass('other-product-btn');
        $('.search-result-container .producttile-container').addClass('d-none');
        $('.search-result-container .matched').addClass('d-none');
        $('.search-result-container .matched').slice(0,9).removeClass('d-none');
    });

    $.fn.productHide = function(){
        $('.search-result-container .producttile-container').addClass('d-none');
        $('.search-result-container .matched').removeClass('d-none');
    };

    $(document).on('click','.plp-loadMore', (e)=>{
        $.fn.productHide(); 
        setTimeout(function(){
            $('.search-result-container .matched:hidden').slice(0,18).removeClass('d-none');
        }, 1);
    });

    //pdp function
    $('.pdp-section-btn .selection').on('click', (e)=>{
        var $this = $(e.currentTarget),
            value = $this.attr('data-value'); 
            
        $('.pdp-section-btn .selection').removeClass('active');
        $this.addClass('active');
        $('.pdp-section-desc').removeClass('active');
        $('.' + value).addClass('active');
    });

    $('.search-result-container .producttile-container').addClass('d-none');
    $('.search-result-container .producttile-container').slice(0,12).removeClass('d-none');

    $('.other-product-btn').on('click', (e)=>{
        $('.search-result-container .producttile-container:hidden').slice(0,9).removeClass('d-none');
    });

    setInterval(function(){ 
        if($('.search-result-container .producttile-container:hidden').length ==0){
            $('.other-product-btn').hide();
        }else{
            $('.other-product-btn').show();
        }

        if($('.search-result-container .matched:hidden').length ==0){
            $('.plp-loadMore').hide();
        }else{
            $('.plp-loadMore').show();
        }
    },);

    //slick slider
    $(window).on('resize',() => {
        $('.slick-content-list .slickTrue').slick('refresh');

        if ($(window).width() <= 768) {
            $('.slick .slickTrue').slick('refresh');
        }
    });

    // alert($(".heading").text());
    $('.slick-content-list .slickTrue').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        
        responsive: [
            {
            breakpoint: 9999,
            settings: "unslick"
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '45px'
            }
            }
        ]
    });
      
    $('.slick .slickTrue').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });

    //article cards
    $.fn.articleThumb = function(){
    $('.articles-container .article-cards:nth-child(1)').addClass('article-1');
    $('.articles-container .article-cards:nth-child(2)').addClass('article-2');
    $('.articles-container .article-cards:nth-child(3)').addClass('article-3');
    
    
        var srcOne = $('.article-1 img').attr('src'),
            srcTwo = $('.article-2 img').attr('src'),
            srcThree = $('.article-3 img').attr('src'),
            link = $('.article-1 .text-link').attr('href');
        
        $('.imgOne').attr('src', srcOne);
        $('.imgTwo').attr('src', srcTwo);
        $('.imgThree').attr('src', srcThree);
        $('.content-list-carousel .text-link').attr('href', link);
        
    };

    $.fn.articleThumb();

    $.fn.slickCounterContentList = function(){
        $('.prod .counter').html('1/3');
        
        $('.slick-content-list .slickTrue').on('afterChange', function(event, slick, currentSlides){
            var slideCount = (currentSlides + 1 + '/3');

            $('.prod .counter').html(slideCount);
        });
    }

    $.fn.slickCounterContentList();

    $.fn.slickCounter = function(){
        $('.slide-counter .counter').html('1/2');
        
        $('.slick .slickTrue').on('afterChange', function(event, slick, currentSlides){
            var slideCount = (currentSlides + 1 + '/2');

            $('.slide-counter .counter').html(slideCount);
        });
    }

    $.fn.slickCounter();

    $('.slick-content-list .slick-wrapper').on('afterChange', function(event, slick, currentSlide){
        if(currentSlide == '0'){
        var slickTopic = $('.module-articles-main-container .article-1 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-1 .article-title').html(),
            link = $('.article-1 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }
        if(currentSlide == '1'){
        var slickTopic = $('.module-articles-main-container .article-2 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-2 .article-title').html(),
            link = $('.article-2 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }
        if(currentSlide == '2'){
        var slickTopic = $('.module-articles-main-container .article-3 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-3 .article-title').html(),
            link = $('.article-3 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }
    });

    $.fn.articleCarousel = function(){
        var slickTopic = $('.module-articles-main-container .article-1 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-1 .article-title').html(),
            link = $('.article-1 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
    };

    $.fn.articleCarousel();

    $.fn.articleThumb = function(){
        $('.survey-result-article .articles-container .article-cards').removeClass('article-1');
        $('.survey-result-article .articles-container .article-cards').removeClass('article-2');
        $('.survey-result-article .articles-container .article-cards').removeClass('article-3');
        $('.survey-result-article .matched-article:eq(0)').addClass('article-match-1');
        $('.survey-result-article .matched-article:eq(1)').addClass('article-match-2');
        $('.survey-result-article .matched-article:eq(2)').addClass('article-match-3');
        
        var srcOne = $('.article-match-1 img').attr('src'),
            srcTwo = $('.article-match-2 img').attr('src'),
            srcThree = $('.article-match-3 img').attr('src'),
            link = $('.article-match-1 .text-link').attr('href');
            
        $('.imgOne').attr('src', srcOne);
        $('.imgTwo').attr('src', srcTwo);
        $('.imgThree').attr('src', srcThree);
        $('.content-list-carousel .text-link').attr('href', link);
            
    };

    $.fn.articleThumb();

    $.fn.articleCarouselFirstItem = function(){
        var slickTopic = $('.module-articles-main-container .article-match-1 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-match-1 .article-title').html(),
            link = $('.article-match-1 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
    }

    $.fn.articleCarouselFirstItem();

    $('.mad-lib .slick-wrapper').on('afterChange', function(event, slick, currentSlide){ //survey result article carousel
        if(currentSlide == '0'){
        var slickTopic = $('.module-articles-main-container .article-match-1 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-match-1 .article-title').html(),
            link = $('.article-match-1 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }
        if(currentSlide == '1'){
        var slickTopic = $('.module-articles-main-container .article-match-2 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-match-2 .article-title').html(),
            link = $('.article-match-2 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }
        if(currentSlide == '2'){
        var slickTopic = $('.module-articles-main-container .article-match-3 .topic-name').html(),
            slickDesc = $('.module-articles-main-container .article-match-3 .article-title').html(),
            link = $('.article-match-3 .text-link').attr('href');

        $('.content-item-desc-and-name .topic-name').html(slickTopic);
        $('.content-item-desc-and-name .article-title').html(slickDesc);
        $('.content-list-carousel .text-link').attr('href', link);
        }

        if(currentSlide == '3'){
            var slickTopic = $('.module-articles-main-container .article-match-4 .topic-name').html(),
                slickDesc = $('.module-articles-main-container .article-match-4 .article-title').html(),
                link = $('.article-match-4 .text-link').attr('href');
    
            $('.content-item-desc-and-name .topic-name').html(slickTopic);
            $('.content-item-desc-and-name .article-title').html(slickDesc);
            $('.content-list-carousel .text-link').attr('href', link);
        }
    });

    $('.start-over').on('click',function() {
        console.log('hello');
        localStorage.removeItem('imalso'); 
        localStorage.clear('looking');
        localStorage.removeItem('ihave');
        localStorage.removeItem('imaa');
    });

    $.fn.articleContentCounter = function(){
        var articleCount = $('.survey-result-article .matched-article').length;
 
        $('.mad-libContent').addClass('d-none');

        if(articleCount === 1){
            $('.mad-libOne').removeClass('d-none');
        }
        if(articleCount === 2){
            $('.mad-libTwo').removeClass('d-none');
        }
    }

    $.fn.articleContentCounter();

    $.fn.slickCounterContentListResults = function(){
        var child = $(".survey-result-article .articles-container > .matched-article").length;

        $('.content-list-counter .counter').html('1/' + child);
        
        $('.slick-content-list .slickTrue').on('afterChange', function(event, slick, currentSlides){
            var slideCount = (currentSlides + 1 + '/' + child);

            $('.content-list-counter .counter').html(slideCount);
        });
    }

    $.fn.slickCounterContentListResults();

    $('.input-name').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
           event.preventDefault();
           return false;
        }
    });

    $('.main-container-404 .search-container').on('click', (e)=>{
        $('#search-button').click();
    });

    $('#wtbBtn').on('click', (e)=>{
        $('#wtbBtn button').click();
    });

    $.fn.scrollD = function(){
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
    }

    var scrollStatus = true;

    $('.burger-icon, .close').on('click', (e)=>{
        $('.search-button').toggleClass('d-none')
        if(scrollStatus == true){
            $.fn.scrollD();
            scrollStatus = false;
        }else{
            scrollStatus = true;
            $('html, body').css({
                overflow: 'visible',
                height: '100%'
            });
        }
    });

    $('.plpLotionLink').on('click', (e)=>{
        localStorage.setItem('plpFilter', 'creams');
    });

    setInterval(function(){ 
        if(localStorage.plpFilter === 'creams'){
            $('.filterApplicationText').text('Creams');
            $('.application-filter .filter-option').removeClass('selected');
            $('.application-filter .filter-option-creams').addClass('selected');
            $('.search-result-container .creamProd').addClass('matched');
            $('.search-result-container .producttile-container').addClass('d-none');
            $('.search-result-text').text('Showing: 11 Products');
            $('.other-product-btn').addClass('d-none');
            setTimeout(function(){
                localStorage.removeItem('plpFilter');
            }, 1000);
        }
    },);

    $('.mad-lib-result-Btn').on('click', (e)=>{
        var q1 = $('.qSelection1'),
            q2 = $('.qSelection2'),
            q3 = $('.qSelection3'),
            q4 = $('.qSelection4');
            

        if ( q1.val() === 'none') {
            console.log('no value1');
            $('.question1 .required-container').addClass('active');
            setTimeout(function(){
            $('.question1 .required-container').removeClass('active');
            }, 1000);
        }else if (q2.val() === 'none') {
            console.log('no value2');
            $('.question2 .required-container').addClass('active');
            setTimeout(function(){
                $('.question2 .required-container').removeClass('active');
            }, 1000);
        }else if (q3.val() === 'none') {
            console.log('no value3');
            $('.question3 .required-container').addClass('active');
            setTimeout(function(){
                $('.question3 .required-container').removeClass('active');
            }, 1000);
        }else if (q4.val() === 'none') {
            console.log('no value4');
            $('.question4 .required-container').addClass('active');
            setTimeout(function(){
                $('.question4 .required-container').removeClass('active');
            }, 1000);
        }else{
            $('.mad-lib-result-Btn').attr('href','/en/mad-lib-results');
        }
    });

    $.fn.signUpStatus = function(){
        var url = $(location).attr('href'),
            parts = url.split("/"),
            last_part = parts[parts.length-1];
       
        if (last_part === 'sign-up?signupstatus=true'){
            var $target = $('html,body'); 
            $target.animate({scrollTop: $target.height()},);
        }
    }

    $.fn.signUpStatus();

    $.fn.faqLink = function(){
        var url = $(location).attr('href'),
            parts = url.split("/"),
            last_part = parts[parts.length-1];
       
        if (last_part === 'education?body-guides'){
            $('.bodyGuides').click();
        }
    }

    $.fn.faqLink();

    // $('.search-result-container .producttile-container').click(function() {
    //     var currUrl = $(this).children().next().children().next().next().next().next().attr('href'),
    //         hostname = window.location.origin;

    //     window.location.href = hostname + currUrl;
    // });

});

function pdpSliderInit() {
    var ismobile = window.matchMedia('(max-width: 767px)');
    var slidesToShow = 4;
    if(ismobile.matches == false) { 
        slidesToShow = 8;
    }
    console.log(slidesToShow);
    var currSlidetrack = $('.pdp-img-thumb .img-thumb-container .slick-track'),
        currCount = currSlidetrack.find('.img-thumb').length;
    if(currCount < slidesToShow) {
        var imgthumbWidth = currSlidetrack.find('.img-thumb').outerWidth(true);
        console.log(imgthumbWidth);
        var currSlideTrackWidth = (parseInt(imgthumbWidth) * parseInt(currCount));
        currSlidetrack.css("width", currSlideTrackWidth +"px");
    }
}
},{}]},{},[1]);
